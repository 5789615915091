import React from 'react';
import { Icon } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { identity } from 'lodash';
import get from 'lodash/get';
import { orderBy } from 'lodash-es';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { City } from 'src/components/City';

import * as Styles from './CompactOpportunityCard.sc';

const StyledTruncatedLocation = styled.span`
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardJobLocation = ({ opportunity }) => {
  const cityName = get(opportunity, 'city.name');
  const countryName = get(opportunity, 'country.name');
  const citySubDivisionName = get(opportunity, 'citySubDivision.name');
  const hierarchicalLocation = opportunity.location;

  if (hierarchicalLocation) {
    const locationName = [
      hierarchicalLocation.formattedName,
      ...orderBy(hierarchicalLocation.parents, 'level', 'desc')
        .filter(item => item.level > 1)
        .map(item => item.formattedName),
    ]
      .filter(identity)
      .join(', ');
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} />
        <StyledTruncatedLocation title={locationName}>
          {locationName}
        </StyledTruncatedLocation>
      </Styles.CompanyInformation>
    );
  }

  if (cityName) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <span>
          {[citySubDivisionName, cityName].filter(identity).join(', ')}
        </span>
      </Styles.CompanyInformation>
    );
  }
  if (opportunity.CityId) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <City id={opportunity.CityId} />
      </Styles.CompanyInformation>
    );
  } else if (countryName) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <span>{countryName}</span>
      </Styles.CompanyInformation>
    );
  }
  return null;
};

CardJobLocation.propTypes = {
  opportunity: PropTypes.object,
};

export default CardJobLocation;
